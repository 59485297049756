.ErrorPopup {
  display: none;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  background: #1F1F1F;
  border: 2px solid #878787;
  border-radius: 10px;
  width: 400px;
  z-index: 8;

  &.visible {
    display: block;
  }

  .content {
    padding: 20px;
    font-size: 25px;
  }

  .TitleText {
    text-align: center;
    margin-bottom: 30px;
    color: #FF2A00;
  }

  .Text {
    font-size: 16px;
  }
}