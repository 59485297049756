.InputField {
  display: inline-block;
  width: 100%;
  max-width: 280px;
  padding: 13px 15px;
  font-size: 15px;
  font-family: 'HBreitsMd';
  border-radius: 10px;
  border: 1px solid #363738;
  outline: none;
  background: #262626;
  color: #fff;

  @media(max-width: 760px) {
    max-width: 100%;
    font-size: 14px;
  }

  &::placeholder {
    font-family: BreitsBold, sans-serif;
    font-size: 16px;

    @media(max-width: 760px) {
      font-size: 14px;
    }
  }
}