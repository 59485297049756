.HeaderButton {
  width: 100%;
  max-width: max-content;
  min-width: 113px;
  border-radius: 8px;
  background: #FF2A00;
  cursor: pointer;
  display: none;

  @media (max-width:1240px) {
    display: block;
  }

  &_Text {
    font-size: 15px;
    margin: 0 auto;
    color: #fff;
    padding: 10px 22px;
    text-align: center;
    line-height: 17px;
  }
}
