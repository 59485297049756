.TabContent {
  width: 316px;
  padding: 0 20px 18px;

  @media(max-width: 760px) {
    width: 100%;
    padding-top: 10px;
  }
}

.CountReview {
  margin-bottom: 24px;

  .SelectList {
    width: 100%;

    .css-lkh0o5-menu {
      z-index: 6;
    }
  }
}

.TemplateReviews {
  margin-bottom: 24px;

  &_CheckboxTemplate {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &_Text {
      font-size: 14px;
      margin-left: 10px;
    }
  }
}

.ReviewBlockItem {
  margin-bottom: 15px;

  label {
    display: inline-block;
    margin-bottom: 6px;
    color: #878787;
    font-size: 14px;
    font-weight: 400;
  }

  &_TextTemplateReview {
    font-size: 13px;
    font-weight: normal;
    line-height: 1.3;
  }
}

.InputField {
  display: inline-block;
  width: 100%;
  max-width: 280px;
  padding: 13px 15px;
  font-size: 15px;
  border-radius: 10px;
  border: 1px solid #363738;
  outline: none;
  background: #262626;
  color: #fff;

  @media (max-width: 760px) {
    font-size: 14px;
    max-width: 100%;
  }
}

.TitleReview {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.CheckerIconContainer {
  display: flex;
  justify-content: space-between;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: #fcfcfc;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 10px;
  }

  &__checker {
    display: flex;
    flex-direction: row;
  }

  img {
    display: inline-block;
    margin-left: 15px;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
  }
}


.CheckBoxCustomContainer {
  display: flex;
  align-items: center;
  width: 15px;
  height: 15px;

  &__input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;

    &:before {
      content:'';
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid #FF2A00;
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;
    }
  }

  &__input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 7px;
    width: 5px;
    height: 12px;
    border: solid #FF2A00;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.PreviewReview {
  width: 100%;
  margin-bottom: 25px;

  &__InfoUser {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  &__Container_Logo {
    display: inline-block;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
  }

  &__Symbol {
    width: 100%;
    height: 100%;
    font-size: 26px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-family: Roboto, sans-serif !important;
  }

  &__Logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 100%;
  }

  &__NameDeveloper {
    font-family: Roboto, sans-serif !important;
    font-size: 15px;
    margin-left: 15px;
    margin-top: 10px;
    font-weight: normal;
  }

  &___ReviewTextContainer {

  }

  &__TextReview,
  &__TextResponseDeveloper {
    font-family: Roboto, sans-serif !important;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: .6px;
  }

  &__TextReview {
    margin-bottom: 15px;
  }

  &__TextResponseDeveloper {
    background: #878787;
    padding: 15px 10px;
    border-radius: 10px;
  }
}

.LoaderContainer {
  display: inline-block;
}

.Loader {
  display: inline-block;
  font-size: 7px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}
@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.5), -1.8em -1.8em 0 0em rgba(255,255,255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.5), 1.8em -1.8em 0 0em rgba(255,255,255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.5), 2.5em 0em 0 0em rgba(255,255,255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.5), 1.75em 1.75em 0 0em rgba(255,255,255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.5), 0em 2.5em 0 0em rgba(255,255,255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.5), -1.8em 1.8em 0 0em rgba(255,255,255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.5), -2.6em 0em 0 0em rgba(255,255,255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}