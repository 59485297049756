:root {
    --star-size: 12px;
    --star-color: #cccccc;
    --star-background: var(--main-color);
}

.stars-landing {
    --percent: calc(var(--rating) / 5 * 97%);
    display: inline-block;
    font-size: var(--star-size);
    font-family: Times;
    line-height: 1;
    text-align: left;
    letter-spacing: 4px;
    padding-left: 0px;
    padding-right: 0px;
}

.stars-landing::before {
    content: '★★★★★';
    letter-spacing: 4px;
    background:
      linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    position: relative;
    font-family: Times;
}

.rtl .stars-landing::before {
    background:
      linear-gradient(270deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
}
