.TabContent {
  width: 316px;
  padding: 0 20px 18px;

  @media (max-width: 760px) {
    width: 100%;
    padding-top: 10px;
  }
}

.ParametersItem {
  margin-bottom: 14px;
}

.DoubleSelect {
  display: flex;
  justify-content: space-between;

  .List {
    width: 48%;
    position: relative;
    z-index: 3;
  }
}

.Button {
  width: 100%;
  text-align: center;
  padding: 16px 0;
  margin-top: 20px;
  background: #FF2A00;
  border-radius: 12px;
  cursor: pointer;
}