@font-face {
  font-family: 'BreitsBold';
  src: local('MyFoBreitsnt'), url('../font/HalvarBreit-Bd.ttf') format('truetype');
}
@font-face {
  font-family: 'BreitsLt';
  src: local('BreitsLt'), url('../font/HalvarBreit-Lt.ttf') format('truetype');
}
@font-face {
  font-family: 'HBreitsMd';
  src: local('HBreitsMd'), url('../font/HalvarBreit-Md.ttf') format('truetype');
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: HBreitsMd !important;
  color: #fff;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  background: #1F1F1F;

}

*, *:after, *:before {
  box-sizing: border-box;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
h1 {
  color: red;
  font-size: 20px;
  font-family: Arial, serif;
}
.css-cs7we2-control,
.css-13cymwt-control,
.css-t3ipsp-control {
  background: #262626 !important;
  border-radius: 10px !important;
  border-color: #363738 !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  position: relative !important;
  z-index: 5 !important;
}

.css-t3ipsp-control {
  box-shadow: none !important;
}

.css-djyvxb-control {
  border-radius: 10px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  position: relative !important;
  z-index: 5 !important;
}

/*.css-b62m3t-container {*/
/*  position: relative !important;*/
/*  z-index: 4 !important;*/
/*}*/
.css-lkh0o5-menu,
.css-1nmdiq5-menu{
  background: #1F1F1F !important;
  position: absolute !important;
  z-index: 9 !important;
}
.css-d7l1ni-option {
  background-color: #5F6368 !important;
}
.css-1dimb5e-singleValue {
  color: #ffffff !important;
}

.css-1p3m7a8-multiValue {
  background-color: #fff !important;
}

.css-12a83d4-MultiValueRemove svg{
  background: #767676;
}

.css-12a83d4-MultiValueRemove:hover {
  background: unset !important;
}

html, body {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

.MainFullScreen {
  height: 100%;
}