.CustomCheckbox {
  display: flex;
  align-items: center;
  width: 15px;
  height: 15px;


  &_input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;

    &:before {
      content:'';
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid #FF2A00;
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;
    }
  }

  &_input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 7px;
    width: 5px;
    height: 12px;
    border: solid #FF2A00;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}