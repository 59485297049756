.NavigationSection {
  display: flex;

  @media (max-width: 760px) {
    flex-direction: column;
  }
}

.NavigationLinkContainer {
  z-index: 10;

  @media (max-width: 760px) {
    position: sticky;
    top: 0;
    background: #1F1F1F;
  }
}

.TabsEditContent {
  width: 301px;

  @media (max-width: 760px) {
    box-sizing: border-box;
    width: 100%;
  }
}

.NavigationLinkList {
  width: 301px;

  @media (max-width: 760px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
}

.NavigationItemList {
  width: 100%;

  .Link {
    display: inline-block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-size: 16px;
    padding: 15px 0 15px 95px;

    @media (max-width: 760px) {
      text-align: center;
      font-size: 14px;
      padding: 20px 0;
      border: 1px solid #363738;
      border-top: none;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.active {
      background: #262626;
      border-right: 4px solid red;

      @media(max-width: 760px) {
        background: linear-gradient(to top, #262626, #242424, #222222, #212121, #1f1f1f);
        border-right: none;
        border-bottom: 3px solid red;
      }
    }
  }
}