.CustomButton {
  width: 100%;
  max-width: max-content;
  border-radius: 8px;
  background: #FF2A00;
  margin-top: 14px;
  cursor: pointer;

  &_Text {
    font-size: 15px;
    color: #fff;
    padding: 10px 22px;
    text-align: center;
    line-height: 17px;
  }
}