.ColorSelectionContainer {
    display: flex;
}

.ColorSelectionBlock {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 20px;

    &:first-child {
        border-right: 1px solid gray;
    }

    &__button {
        border: 2px solid #fff;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: blue;

        &_blue {
            background-color: rgb(38, 103, 224);
        }

        &_green {
            background-color: green;
        }
    }

    &__subtitle {

    }

    &__picker {
        position: absolute;
        top: 100%;
        right: -100%;
        z-index: 999;

        @media (max-width: 768px) {
            top: 110%;
            left: -50%;
            right: -50%;
        }
    }
}