.progress-bar-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin: auto;
}

.progress-bar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider {
  width: 100%;
}

.progress {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  height: 20px;
  margin-top: 10px;
}

.progress-bar {
  height: 100%;
  background-color: #76c7c0;
  text-align: center;
  color: white;
  line-height: 20px;
}
