.TextAriaField {
  width: 100%;
  border: 1px solid #363738;
  outline: none;
  background: #262626;
  color: #fff;
  padding: 6px;
  border-radius: 5px;
  font-size: 15px;
  font-family: HBreitsMd !important;

  @media (max-width: 760px) {
    font-size: 14px;
  }

  &::placeholder {
    font-family: BreitsBold, sans-serif;
    font-size: 16px;

    @media (max-width: 760px) {
      font-size: 14px;
    }
  }
}