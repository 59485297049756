.PreviewContainer {
  width: 100%;
  max-width: 390px;

  @media(max-width: 640px) {
    width: 390px;
  }
}

.PWAPreviewFixed {
  position: fixed;
  top: 20px;
}

.PWAPreviewNormal {
  position: static;
}

.HeaderPreview {
  display: flex;
  justify-content: center;
}

.InfoDownload {
  // display: flex;
  // justify-content: space-between;
  // align-items: flex-start;

  //  &_NameLand {
  //    width: 100%;
  //    max-width: 283px;
  //    padding: 0 10px;
  //    border-bottom-left-radius: 10px;
  //    border-bottom-right-radius: 10px;
  //    margin-right: 10px;

  //    &_Text {
  //      text-align: center;
  //      font-size: 16px;
  //      line-height: 23px;
  //      font-weight: normal;
  //      padding: 14px 0;
  //      overflow: hidden;
  //      white-space: nowrap;
  //      text-overflow: ellipsis;

  //      @media (max-width: 760px) {
  //       font-size: 14px;
  //      }
  //    }
  //  }

  &_SendLand {
    width: 100%;
    max-width: max-content;
    min-width: 113px;
    border-radius: 8px;
    background: #FF2A00;
    cursor: pointer;
    margin-bottom: 10px;

    &_Button {
      font-size: 15px;
      margin: 0 auto;
      color: #fff;
      padding: 10px 22px;
      text-align: center;
      line-height: 17px;

      @media (max-width: 760px) {
        font-size: 14px;
       }

      img {
        width: 32px;
        height: 32px;
        object-fit: contain;
      }
    }
  }
}