.TabContent {
  width: 316px;
  padding: 0 20px 18px;

  @media(max-width: 760px) {
    width: 100%;
    padding-top: 10px;
  }
}

.Section {
  margin-bottom: 24px;

  &__hide {
    display: none !important;
  }

  .SelectList {
    position: relative;
    z-index: 9;
  }


  .InputField {
    display: inline-block;
    width: 100%;
    max-width: 280px;
    padding: 13px 15px;
    font-size: 15px;
    border-radius: 10px;
    border: 1px solid #363738;
    outline: none;
    background: #262626;
    color: #fff;

    &::placeholder{
      font-family: BreitsBold, sans-serif;
      font-size: 16px;
    }

    @media (max-width: 760px) {
      font-size: 14px;
    }
  }

  .CustomTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    max-width: 200px;
    margin-bottom: 14px;

    @media (max-width: 760px) {
      font-size: 14px;
    }
  }

  .DownloadIconApp {
    width: 133px;
    height: 133px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 5px 15px;
    border: 2px dashed #FF2A00;
    border-radius: 3px;
    cursor: pointer;
    position: relative;

    &_Icon {
      width: 45px;
      height: 45px;
      object-fit: contain;
    }

    &_TextBlock {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &_Span {
        color: #878787;
        font-size: 12px;
        line-height: 14px;
      }
    }

    &_IconButtonDowload {
      border: 1px solid yellow;
      display: inline-block;
      width: 143px;
      height: 143px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 3;
    }
  }

  .SelectIconBlock {
    display: flex;
  }

  .DownloadIconPreview {
    width: 133px;
    height: 133px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .ContainerItem {
    display: flex;
  }

  .DownloadMultiScreen {
    position: relative;
    width: 100%;

    &_MultiImageButtonDowload {
      display: inline-block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 2;
      border: 1px solid yellow;
    }
  }

  .PreviewMulti {
    display: flex;
    flex-wrap: wrap;

    &_Image {
      display: inline-block;
      width: 133px;
      height: 133px;
      object-fit: contain;
    }
  }

  .ScreenshotList {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;

    .ScreenItem {
      width: 100%;
      max-width: 50%;

      @media (max-width: 760px) {
        width: 133px;
        max-width: 100%;
      }

      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 10px;
      }

      &:nth-child(odd) {
        margin-right: 10px;
      }
    }
  }

  .PreviewImage {
    width: 120px;
    height: 133px;
    object-fit: cover;
  }
}

.PreviewImageContainer {
  position: relative;

  &__ContainerIconClose {
    background: #fff;
    width: 29px;
    height: 29px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 100%;
    top: -15px;
    cursor: pointer;
    z-index: 7;
  }

  &__IconClose {
    width: 35px;
    height: 35px;
    object-fit: contain;
  }
}

.ContainerChecker {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.CheckBoxCustomContainer {
  display: flex;
  align-items: center;
  width: 15px;
  height: 15px;
  margin-right: 15px;

  &__input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;

    &:before {
      content:'';
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid #FF2A00;
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;
    }
  }

  &__input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 7px;
    width: 5px;
    height: 12px;
    border: solid #FF2A00;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.SectionTemplate {
  margin-top: 15px;
}

.IconTemplate {
  margin-top: 15px;

  .ContainIconTemplate {
    width: 135px;
    height: 135px;
    border-radius: 15px;
    margin-bottom: 15px;

    &.ActiveIconTemplate {
      border: 2px solid #FF2A00;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 15px;
    }
  }


}