.container {
  align-items: center;
  background-color: #1f1f1f;
  display: flex;
  justify-content: space-between;
  left: 50%;
  margin: 0 auto;
  max-width: 1440px;
  padding: 23px 20px;

  @media (max-width: 760px) {
    padding-block: 23px;
  }
}

.LogoNavBlock {

  .LogoContainer {
    width: 100%;
    max-width: 165px;
    height: 25px;

    .LogoImage {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.LanguagesSelectContainer {
  @media (max-width: 1240px) {
    display: none;
  }

  .LanguagesList {
    width: 50px;

    &_Item {
      text-align: center;
      padding: 10px 0;

      &:last-child {
         display: none;
      }
    }
  }
}